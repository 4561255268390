import React from 'react'

import { Script } from 'gatsby-script'

export default function Contact() {
  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Contactez-nous</h1>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Il est des situations où il n’est pas bon de rester seul avec ses questions. Problèmes de fissure. Présence d’humidité, litige de la construction. Besoin d’un avis technique pour l'achat d’un logement neuf ou ancien.
            </p>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Nous vous accompagnons efficacement et spécifiquement en fonction de vos besoins et vos attentes. Recherche d’un expert bâtiment en Rhône-Alpes, ou ailleurs. Vous avez besoin de neutralité pour le choix des solutions de réparations, et d’indépendance vis-à-vis des professionnels du bâtiment, nous sommes à vos côtés pour vous aider à faire les bons choix.
            </p>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Nous nous engageons à vous répondre dans les plus brefs délais. Nous mettons un point d’honneur à ne laisser aucune demande sans réponse!
            </p>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Si vous avez besoin d’une expertise bâtiment, fissure, humidité, simplement d’un avis technique, ou d’une intervention d’un professionnel, vous pouvez également faire une demande de devis par ici.
            </p>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Pour tout autre renseignement, vous pouvez nous contacter par l’intermédiaire du formulaire :
            </p>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <form action="https://formulaire.koudepouce.fr/duboisexpertise/contact/" method="POST" className="grid grid-cols-1 gap-y-6">
              <div>
                <label htmlFor="nom" className="sr-only">
                  Nom
                </label>
                <input
                  type="text"
                  name="nom"
                  id="nom"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  placeholder="Nom *"
                  required
                />
              </div>
              <div>
                <label htmlFor="prenom" className="sr-only">
                  Prénom
                </label>
                <input
                  type="text"
                  name="prenom"
                  id="prenom"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  placeholder="Prénom"
                />
              </div>
              <div>
                <label htmlFor="cp" className="sr-only">
                  Code postal
                </label>
                <input
                  type="text"
                  name="cp"
                  id="cp"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  placeholder="Code postal *"
                  required
                />
              </div>
              <div>
                <label htmlFor="telephone" className="sr-only">
                  Téléphone
                </label>
                <input
                  type="text"
                  name="telephone"
                  id="telephone"
                  autoComplete="tel"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  placeholder="Téléphone *"
                  required
                />
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  placeholder="Email *"
                  required
                />
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md"
                  placeholder="Message *"
                  defaultValue={''}
                  required
                />
              </div>

              <div>
                <div className="mt-1">
                  <input className="jCaptcha py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md" type="text" placeholder="Résultat" />
                </div>
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  CGU
                </label>
                <input
                  type="checkbox"
                  id="cgu"
                  name="cgu"
                  className="inline mr-3 shadow-sm py-1 px-3 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  required
                /> J'accepte les <a href="/legal/cgu/" className="text-blue-500" target="_blank" rel="noreferrer">Conditions Générales d'Utilisation</a> *
              </div>

              <div>
                <button
                  type="submit"
                  className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Envoyer
                </button>
              </div>
            </form>
            <Script src="/jCaptcha.js" />
            <p className="mt-3 text-xs leading-6 text-gray-500">
              Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par le site Dubois Expertise.fr et édité par la société Dubois Expertise visant à traiter votre demande. Elles sont conservées pendant au moins 3 ans et sont destinées au service marketing et au service commercial. Nos mentions légales sont ici.<br />
              Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d’accès aux données vous concernant et les faire rectifier en contactant : Dubois Expertise, Service de la protection des données, 31 Chemin de Crépieux, 69300 Caluire et Cuire, ou par e-mail sur matthieu[AROBASE]dubois-expertise.fr en précisant dans l’objet du courrier « Droit des personnes » et en joignant la copie de votre justificatif d’identité.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
