import React from "react"
import { Helmet } from "react-helmet"
import Banner from "../../blocs/banner"
import Header from "../../blocs/header"
import Footer from "../../blocs/footer"
import Contact from "../../blocs/contact"
//import Ariane from "../../blocs/ariane"

/*
const fil_ariane = [
    { name: 'Contact', href: '/contact/', current: false },
    { name: 'Contactez-nous', href: '', current: true },
  ]

  <Ariane filariane={fil_ariane} />
*/
export default function Index() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Dubois-expertise.fr</title>
        <meta name="description" content="Vous avez des questions. Vous cherchez un expert en bâtiment ? Contactez Dubois Expertise." />
        <link rel="canonical" href="https://www.dubois-expertise.fr/contacter-nous/" />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Banner />
        <Header />
      </header>

      <main className="relative">
        <Contact />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}